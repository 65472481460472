import React, { useEffect, useState } from "react"

import style from "./modal.module.css"

import { Link } from "gatsby"
import PrimaryButton from "../components/primarybutton"
import SecondaryButton from "../components/secondarybutton"

import useForm from "../hooks/useForm"
import validate from "../validation/bookMeetingValidation"

import axios from "axios"

//import config from 'gatsby-plugin-config';

import logo from "../images/cognitive-logo.png"

import cross from "../vectors/cross.svg"

import { gsap } from "gsap"

const Modal = ({ modalText, showModal, isModalOpen }) => {
  var d = new Date()
  var n = d.getDay()

  //console.log(`hello ${process.env.TEST_KEY}`)

  var time = d.getUTCHours()

  let day = ""
  if (time >= 16) {
    if (n == 6) {
      n = 0
    } else {
      n = n + 1
    }
  }
  switch (n) {
    case 0:
      day = "mon"
      break
    case 1:
      day = "mon"
      break
    case 2:
      day = "tue"
      break
    case 3:
      day = "wed"
      break
    case 4:
      day = "thu"
      break
    case 5:
      day = "fri"
      break
    case 6:
      day = "mon"
      break
  }

  const { values, errors, handleChange, handleSubmit } = useForm(
    bookMeeting,
    validate
  )

  function bookMeeting() {
    values["daySelected"] = daySelected

    axios({
      method: "POST",
      url: "https://formspree.io/f/xpzodvgp",
      data: values,
    })
      .then(r => {
        setModalSubmited(true)
      })
      .catch(r => {})

    // //alert(values.telephone + values.name + values.company + daySelected);
  }

  const [daySelected, changeDaySelected] = useState(day)

  const [modalSubmited, setModalSubmited] = useState(false)

  const handleClick = e => {
    e.stopPropagation()
  }

  const sendEmail = () => {
    showModal()
    window.location.assign("mailto:info@cognitivesoftware.co.uk")
  }

  let modalRef = null

  useEffect(() => {
    if (isModalOpen) {
      gsap.to(modalRef, { y: "-10px", duration: 0.5 })
    } else {
      //gsap.fromTo(modalRef, { y: "0px" }, { y: "2000px" })
    }
  }, [])

  return (
    <div
      className={style.modal}
      onClick={handleClick}
      ref={e => (modalRef = e)}
    >
      <div className={style.exitModal}>
        <img src={cross} onClick={() => showModal()} />
      </div>
      <div className={style.exitButton}></div>
      {!modalSubmited ? (
        <form onSubmit={handleSubmit} noValidate>
          <div className={style.itemsCentered}>
            <div className={style.modalTitle}>
              <h2>Book a {modalText}</h2>
            </div>
            <div className={style.modalInput}>
              <div className={style.telephoneNumber}>
                <label>
                  <h4>Telephone</h4>
                </label>
                <input
                  type="text"
                  name="modalTelephone"
                  className={
                    errors.modalTelephone
                      ? style.textInputError
                      : style.textInput
                  }
                  autocomplete="none"
                  onChange={handleChange}
                  value={values.modalTelephone || ""}
                  required
                  placeholder={errors.modalTelephone}
                />
              </div>
            </div>
            <div className={style.modalInput}>
              <label>
                <h4>Name</h4>
              </label>
              <input
                type="text"
                autocomplete="none"
                className={
                  errors.modalName ? style.textInputError : style.textInput
                }
                name="modalName"
                onChange={handleChange}
                value={values.modalName || ""}
                required
                placeholder={errors.modalName}
              />
            </div>
            <div className={style.modalInput}>
              <label>
                <h4>Company</h4>
              </label>
              <input
                type="text"
                autocomplete="none"
                className={
                  errors.modalCompany ? style.textInputError : style.textInput
                }
                name="modalCompany"
                onChange={handleChange}
                value={values.modalCompany || ""}
                required
                placeholder={errors.modalCompany}
              />
            </div>
            <div className={style.datePicker}>
              <div className={style.weekLabel}>
                <label>
                  <h4>When</h4>
                </label>
              </div>
              <div className={style.week}>
                <div
                  className={
                    daySelected == "mon" ? style.daySelected : style.day
                  }
                  onClick={() => changeDaySelected("mon")}
                >
                  <h3>Mon</h3>
                </div>
                <div
                  className={
                    daySelected == "tue" ? style.daySelected : style.day
                  }
                  onClick={() => changeDaySelected("tue")}
                >
                  <h3>Tue</h3>
                </div>
                <div
                  className={
                    daySelected == "wed" ? style.daySelected : style.day
                  }
                  onClick={() => changeDaySelected("wed")}
                >
                  <h3>Wed</h3>
                </div>
                <div
                  className={
                    daySelected == "thu" ? style.daySelected : style.day
                  }
                  onClick={() => changeDaySelected("thu")}
                >
                  <h3>Thu</h3>
                </div>
                <div
                  className={
                    daySelected == "fri" ? style.daySelected : style.day
                  }
                  onClick={() => changeDaySelected("fri")}
                >
                  <h3>Fri</h3>
                </div>
              </div>
            </div>
            <div className={style.bookMeeting}>
              <PrimaryButton buttonText={"BOOK NOW"} />
              <SecondaryButton
                buttonText={"PREFER TO EMAIL"}
                onClick={sendEmail}
              />
            </div>
          </div>
        </form>
      ) : (
        <div className={style.itemsCentered}>
          <div className={style.modalTitle}>
            <h2>You have booked a {modalText} </h2>
          </div>
        </div>
      )}
    </div>
  )
}
export default Modal
