import React, { forwardRef } from "react"

import style from "./firstsection.module.css"

import { Link } from "gatsby"
import PrimaryButton from "../components/primarybutton"

const FirstSection = ({
  showModel,
  isModalOpen,
  mountainsStart,
  headline1Ref,
  headline2Ref,
  headline3Ref,
  headline4Ref,
}) => {
  return (
    <header className={style.firstSection}>
      <div className={style.headline} ref={mountainsStart}>
        <div className={style.mainHeading}>
        <h1 ref={headline1Ref}>We help businesses eliminate waste from their workflows using automations tools</h1>
        </div>
        <div className={style.ctaButton}>
        <h6 ref={headline2Ref}>Schedule a free consultation and see how much time your business can save</h6>
        <div className={style.headlineButton} ref={headline3Ref}>
          <PrimaryButton
            buttonText={"ARRANGE A FREE CONSULTATION"}
            onClick={() => window.location.href = "mailto:info@cognitivesoftware.co.uk"}
          />
        </div>
        </div>
      </div>
    </header>
  )
}
export default FirstSection
