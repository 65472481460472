import React from "react"

import style from "./secondsection.module.css"

import { Link } from "gatsby"
import SecondaryButton from "./secondarybutton"

import kierlogo from "../images/kier.svg"

const SecondSection = ({ secondsectionRef, minutesRef, minutes }) => {
  return (
    <div className={style.secondSection} ref={secondsectionRef}>
      <div className={style.company}>
        <img src={kierlogo} />
      </div>
      <span className={style.inline}>
        <div className={style.minutesNum}>
          <h2 ref={minutesRef}>{minutes}</h2>
        </div>
        <div className={style.minutesText}>
          <h2 ref={minutesRef}>minutes</h2>
        </div>
        <div className={style.time}>
          <h2>Earned back every day</h2>
        </div>
      </span>
      <div className={style.testimonial}>
        <h6>
          We worked with Kier to develop an automation solution for their admin team. The software saved the team countless hours a day. This can now be spent on higher valued tasks.
        </h6>
      </div>
      <div className={style.inline}>
        <p>Work in the highways sector? Check out the Stats Robot.</p>
        <SecondaryButton
          buttonText={"FIND OUT MORE"}
          onClick={() =>
            (window.location.href =
              "https://statsrobot.cognitivesoftware.co.uk/")
          }
        />
      </div>
    </div>
  )
}
export default SecondSection
