import React from "react"

import style from "./notice.module.css"

import { Link } from "gatsby"

const Notice = () => {
  return (
    <div className={style.noticeBanner}>
      <h4>BLACK LIVES MATTERS</h4>
    </div>
  )
}
export default Notice
