import React from "react"

import style from "./menu.module.css"

import { Link } from "gatsby"
import PrimaryButton from "../components/primarybutton"

import logo from "../images/cognitive-logo.png"

const Menu = ({
  showModel,
  isModalOpen,
  menuRef,
  emailRef,
  logoRef,
  navRef,
}) => {
  return (
    <header
      className={isModalOpen ? style.mainNavModal : style.mainNav}
      ref={menuRef}
    >
      <div className={style.logo}>
        <img src={logo} width={150} ref={logoRef} />
      </div>
      <div className={style.menuLinks} ref={navRef}>
        {/* <nav>Solutions</nav>
        <nav>Contact</nav> */}
        <nav>
          <a
            href="mailto:info@cognitivesoftware.co.uk"
            className={style.email}
            ref={emailRef}
          >
            info@cognitivesoftware.co.uk
          </a>
        </nav>
        {/*<PrimaryButton*/}
        {/*  buttonText={"BOOK A MEETING"}*/}
        {/*  onClick={() => showModel("Meeting")}*/}
        {/*/>*/}
      </div>
    </header>
  )
}
export default Menu
